import React from "react";
import "./Contact.css";
import { Container } from "react-bootstrap";

function Contact() {
  return (
    <>
      <section className="contact">
        <Container>
          <div className="contact-container green">
            <div className="design-approach-card p-0">
              <h6>CONTACT</h6>
            </div>
            <div className="expert-team">
              <h2>Let's design your dream projects with Expert Team</h2>
              <p>
                WonCurious about our process and how we build brands with our
                strategy? Let's talk! Request a consultation today and discover
                the secrets to our success. Get ready to unleash your business's
                potential.{" "}
              </p>
              <a href="#" className="btn-black">
                Get in Touch
              </a>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Contact;
