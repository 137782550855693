import React, { useState } from "react";
import "./OurTeam.css";
import { Container, Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import avatar1 from "../../assets/images/avatar1.png";
import avatar2 from "../../assets/images/avatar2.png";
import avatar3 from "../../assets/images/avatar3.png";
import avatar4 from "../../assets/images/avatar4.png";
import avatar5 from "../../assets/images/avatar5.png";
import avatar7 from "../../assets/images/avatar7.png";
import avatar8 from "../../assets/images/avatar8.png";
import avatar9 from "../../assets/images/avatar9.png";
import avatar10 from "../../assets/images/avatar10.png";
import avatar11 from "../../assets/images/avatar11.png";

function OurTeam() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="our-team">
        <Container>
          <h6 className="section-title">Our Team</h6>
          <div className="recent-work">
            <h3>Expert Team of Developers</h3>
          </div>
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card blue our-team-card">
                <h6>JOIN THE TEAM</h6>
                <h4 className="work-card-title design-approach-title our-team-title">
                  Want to Work Expert Team?
                </h4>
                <h6>We helped Hinders of, People in Growing Potentially</h6>
                <button
                  className="btn-black get-in-touch"
                  onClick={handleShow}
                >
                  Join us
                </button>
                {/* modal */}
                <Modal
                  show={show}
                  onHide={handleClose}
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="group-input group-input2">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label from-label-style"
                          >
                            Name
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter you name"
                          />
                        </div>

                        <div className="group-input group-input2">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label from-label-style"
                          >
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="name@gmail.com"
                          />
                        </div>

                        <div className="group-input group-input2 group-input1">
                          <label
                            for="exampleFormControlTextarea1"
                            className="form-label from-label-style"
                          >
                            Phone no.
                          </label>
                          <div
                            controlid="formBasicEmail"
                            className="mb-3 input-group"
                          >
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              +91
                            </span>
                            <input
                              placeholder="Enter Phone Number"
                              type="number"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="group-input group-input2">
                          <label
                            for="exampleFormControlTextarea1"
                            className="form-label from-label-style"
                          >
                            Why We Choose You
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Project detail"
                          ></textarea>
                        </div>
                      </form>
                      <div className="file-upload">
                        <div className="file-select">
                          <button className="btn add-attachment-btn">
                            <i>
                              <Icon icon="entypo:attachment" />
                            </i>
                            <span>Attachment Resume</span>
                          </button>
                          <div className="file-select-name">File</div>
                          <div className="imagePreview"></div>
                          <input
                            type="file"
                            name="files[]"
                            className="profileimg"
                          />
                        </div>
                      </div>
                      <a
                        href="#"
                        className="send-request submit2"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </Modal>
                {/* modal close */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card orange our-team-card">
                <i>
                  <img src={avatar1} alt="" />
                </i>
                <h6 className="text-center developers">SHUBHAM LAKHANI</h6>
                <h6 className="text-center">CEO</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card green our-team-card">
                <i>
                  <img src={avatar2} alt="" />
                </i>
                <h6 className="text-center developers">SHUBHAM BAMBHAROLIYA</h6>
                <h6 className="text-center">CEO</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card yellow our-team-card">
                <i>
                  <img src={avatar3} alt="" />
                </i>
                <h6 className="text-center developers">ROSHNI KUMBHANI</h6>
                <h6 className="text-center">PROJECT MANAGER</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card purple our-team-card">
                <i>
                  <img src={avatar4} alt="" />
                </i>
                <h6 className="text-center developers">DHRUVISHA MANGUKIYA</h6>
                <h6 className="text-center">UI UX DESIGNER</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card pink our-team-card">
                <i>
                  <img src={avatar5} alt="" />
                </i>
                <h6 className="text-center developers">DHYEYASHA KAPURIYA</h6>
                <h6 className="text-center">FRONT-END DEVELOPER</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card green our-team-card">
                <i>
                  <img src={avatar8} alt="" />
                </i>
                <h6 className="text-center developers">MAITRY KAJAVADRA</h6>
                <h6 className="text-center">REACT-NATIVE DEVELOPER</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card blue our-team-card">
                <i>
                  <img src={avatar7} alt="" />
                </i>
                <h6 className="text-center developers">RUCHITA GEVARIYA</h6>
                <h6 className="text-center">REACT-JS DEVELOPER</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card orange our-team-card">
                <i>
                  <img src={avatar8} alt="" />
                </i>
                <h6 className="text-center developers">RAJVEE JIVANI</h6>
                <h6 className="text-center">REACT-JS DEVELOPER</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card pink our-team-card">
                <i>
                  <img src={avatar9} alt="" />
                </i>
                <h6 className="text-center developers">SHIVANI SAVALIYA</h6>
                <h6 className="text-center">WORDPRESS DEVELOPER</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card purple our-team-card">
                <i>
                  <img src={avatar10} alt="" />
                </i>
                <h6 className="text-center developers">JASH VANANI</h6>
                <h6 className="text-center">ANDROID DEVELOPER</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card yellow our-team-card">
                <i>
                  <img src={avatar11} alt="" />
                </i>
                <h6 className="text-center developers">NEEL SAVANI</h6>
                <h6 className="text-center">IOS DEVELOPER</h6>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default OurTeam;
