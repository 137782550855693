import React from "react";
import "./Footer.css";
import footerlogo from "../../assets/images/brand_logo.svg";
import { Container } from "react-bootstrap";
import { Icon } from "@iconify/react";

function Footer() {
  return (
    <>
      <footer>
        <Container>
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <a href="" className="footerlogo">
                <img src={footerlogo} alt="logo" />
              </a>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <h4 className="footer-title">Company</h4>
              <ul className="footermenu">
                <li className="d-flex align-items-center">
                  <i>
                    {/* <span
                      className="iconify"
                      data-icon="ic:round-local-phone"
                    ></span> */}
                    <Icon className="iconify" icon="ic:baseline-local-phone" />
                  </i>
                  <div>
                    <h6>Phone Number</h6>
                    <a href="tel:+91 78747 12144" className="footer-text">
                      +91 78747 12144
                    </a>
                  </div>
                </li>
                <li className="d-flex align-items-center">
                  <i>
                    {/* <span className="iconify" data-icon="mdi:email-multiple"></span> */}
                    <Icon
                      className="iconify"
                      icon="mdi:email-multiple-outline"
                    />
                  </i>
                  <div>
                    <h6>Email Us</h6>
                    <a
                      href="mailto:info@codespectsolution.com?subject=Subject&body=message%20goes%20here"
                      className="footer-text"
                    >
                      info@codespectsolution.com
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="mail">
                <h4 className="footer-title">Location</h4>
                <p className="footer-text">
                  400-Unique Square, Singanpore, Causeway-Link Road,
                  Surat-395004, Gujarat, India
                </p>
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.0458043785234!2d72.80317927605823!3d21.230032180833746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fb02357b183%3A0xb89ae3673ecb33d1!2sUNIQUE%20SQUARE!5e0!3m2!1sen!2sin!4v1683002099754!5m2!1sen!2sin"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <p className="copyright-text">
            Copyright © 2018 CodeSpect Solution | All Rights Reserved
          </p>
        </Container>
      </footer>
      {/* <a
        onclick="topFunction()"
        id="myBtn"
        title="Go to top"
        style="display: block;"
      ></a> */}
    </>
  );
}

export default Footer;
