import React from "react";
import HomeBanner from "../../Components/HomeBanner/HomeBanner";
import Brands from "../../Components/Brands/Brands";
import WorkCards from "../../Components/WorkCards/WorkCards";
import OurWork from "../../Components/OurWork/OurWork";
import DesignApproach from "../../Components/DesignApproach/DesignApproach";
import OurTeam from "../../Components/OurTeam/OurTeam";
import Contact from "../../Components/Contact/Contact";

function HomePage() {
  return (
    <>
      <HomeBanner />
      <Brands />
      <WorkCards />
      <OurWork />
      <DesignApproach />
      <OurTeam />
      <Contact />
    </>
  );
}

export default HomePage;
