import React from "react";
import "./HomeBanner.css";

function HomeBanner() {
  return (
    <>
      {/* Home Banner */}
      <section className="home-banner">
        <div className="container">
          <div className="home-banner-info">
            <h2 className="section-title">
              Our customized software solutions can transform the way your
              business growth.
            </h2>
            <p className="section-info">
              Codespect is a leading provider of IT support and services,
              catering to clients since 2018. Our expert team of IT engineers is
              committed to overcoming complex IT challenges and providing
              customized solutions that precisely align with your business
              needs. We take great pride in delivering unrivaled assistance and
              support to foster the success of your organization.
            </p>
          </div>
        </div>
      </section>
      {/* Home Banner Close  */}
    </>
  );
}

export default HomeBanner;
