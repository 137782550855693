import React from "react";
import "./DesignApproach.css";
import { Container } from "react-bootstrap";

function DesignApproach() {
  return (
    <>
      <section className="design-approach">
        <Container>
          <h6 className="section-title">
            An Effective Approach to Project Execution
          </h6>
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card green">
                <h4 className="work-card-title">Project Discovery</h4>
                <p className="info-text">
                  {" "}
                  Codepsect solution conducting a thorough project discovery
                  phase, which involves understanding the client's objectives,
                  requirements, and expectations. This step helps to establish
                  clear project goals and ensures a shared understanding between
                  the agency and the client.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card blue">
                <h4 className="work-card-title">Planning and Strategy</h4>
                <p className="info-text">
                  Once the project goals are defined, We develops a
                  comprehensive plan and strategy to achieve them. This includes
                  outlining project timelines, allocating resources, defining
                  deliverables, and establishing a project management framework.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card purple">
                <h4 className="work-card-title">Design and Development</h4>
                <p className="info-text">
                  In this step, Our team of experts designs and develops the
                  required IT solutions, which could include software
                  development, system integrations, database design, or website
                  creation. The focus is on building robust, scalable, and
                  user-friendly solutions aligned with the client's specific
                  needs.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card pink">
                <h4 className="work-card-title">
                  Testing and Quality Assurance
                </h4>
                <p className="info-text">
                  Before the final implementation, rigorous testing and quality
                  assurance measures are conducted to identify and resolve any
                  bugs, errors, or usability issues. This step ensures that the
                  solutions are reliable, functional, and meet the required
                  standards of performance and security.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card orange">
                <h4 className="work-card-title">
                  Implementation and Deployment
                </h4>
                <p className="info-text">
                  Once the solutions have been thoroughly tested and approved,
                  We proceeds with the implementation and deployment phase. This
                  involves integrating the developed solutions into the client's
                  existing IT infrastructure or launching new systems, ensuring
                  a smooth transition and minimal disruption to business
                  operations.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card yellow">
                <h4 className="work-card-title">Training and Support</h4>
                <p className="info-text">
                  To enable seamless adoption of the new IT solutions, We
                  provides comprehensive training and support to the client's
                  team. This step ensures that users are equipped with the
                  necessary skills and knowledge to effectively utilize the
                  implemented solutions. Ongoing support is also provided to
                  address any issues or concerns that may arise.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="design-approach-card blue">
                <h4 className="work-card-title">Monitoring and Evaluation</h4>
                <p className="info-text">
                  After the project is implemented, We continues to monitor and
                  evaluate the performance of the solutions. This allows for
                  continuous improvement, optimization, and identification of
                  areas where further enhancements or adjustments may be
                  required.
                </p>
              </div>
            </div>
          </div>
          <div className="digital-experience">
            <div className="recent-work mb-5">
              <h3>
                Elevating Digital Experiences: Inspiring Design Innovations for
                GOATS.
              </h3>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <p className="info-text info-text-1 mb-4">
                  We adopt a collaborative approach and aim to provide you with
                  the finest design solutions through our team of experts. Our
                  goal is to elevate digital experiences to new levels, offering
                  design inspirations tailored specifically for GOATS.
                </p>
              </div>
              <div className="col-lg-6 col-md-6">
                <p className="info-text info-text-1 mb-4">
                  We employ a collaborative approach and are dedicated to
                  assisting you with top-notch design solutions utilizing our
                  team of skilled experts. Our aim is to raise the bar for
                  digital experiences, providing design inspirations that cater
                  specifically to GOATS.
                </p>
              </div>
              <div className="col-lg-6 col-md-6">
                <p className="info-text info-text-1">
                  Our methodology involves collaboration, and we are here to
                  offer you the finest design solutions with the assistance of
                  our team of professionals. Our focus is to take digital
                  experiences to unprecedented heights, providing design
                  inspirations tailored specifically for GOATS.
                </p>
              </div>
              <div className="col-lg-6 col-md-6">
                <p className="info-text">
                  We adopt a collaborative approach and are dedicated to
                  assisting you with the best design solutions through our
                  expert team. Our goal is to elevate digital experiences and
                  provide new levels of design inspiration specifically tailored
                  for GOATS.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default DesignApproach;
