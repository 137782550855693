import React from "react";
import "./OurWork.css";
import noteefy from "../../assets/images/noteefy.png";
import orbofi from "../../assets/images/orbofi.png";
import Shurooq from "../../assets/images/shurooq.png";
import eLocker from "../../assets/images/elocker.png";
import Goblaq from "../../assets/images/goblaq.png";
import CustomSpace from "../../assets/images/custom-space.png";
import { Container } from "react-bootstrap";

function OurWork() {
  const workData = [
    {
      image: noteefy,
      title: "Noteefy.app",
      description:
        "The ultimate productivity tool that will revolutionize the way you organize and manage your notes. With its user-friendly interface and powerful features, Noteefy.app empowers you to take control of your information effortlessly.",
      url: "https://noteefy.app/",
    },
    {
      image: orbofi,
      title: "OrboFi.com",
      description:
        "Your gateway to the future of finance. We're here to revolutionize the way you manage and grow your money. With our cutting-edge platform, you can take control of your financial journey like never before.",
      url: "https://www.orbofi.com/",
    },
    {
      image: Shurooq,
      title: "Shurooq.gov.ae",
      description:
        "Your gateway to the vibrant opportunities offered by the Sharjah Investment and Development Authority. We're here to ignite your potential and help you thrive in one of the most dynamic business environments in the region.",
      url: "https://shurooq.gov.ae/",
    },
    {
      image: eLocker,
      title: "eLocker.com",
      description:
        "Your ultimate solution for secure and convenient storage needs. We're here to simplify your life and provide you with peace of mind when it comes to storing your belongings.",
      url: "https://elocker.com/",
    },
    {
      image: Goblaq,
      title: "Goblaq.com",
      description:
        "Your ultimate solution for secure and convenient storage needs. We're here to simplify your life and provide you with peace of mind when it comes to storing your belongings.",
      url: "https://www.goblaq.com/",
    },
    {
      image: CustomSpace,
      title: "CustomSpace.com",
      description:
        "Your ultimate solution for secure and convenient storage needs. We're here to simplify your life and provide you with peace of mind when it comes to storing your belongings.",
      url: "https://customspace.shop/#/",
    },
  ];
  return (
    <>
      <section className="our-work">
        <Container>
          <h6 className="section-title">Our Work</h6>
          <div className="recent-work">
            <h3>Recent WORK</h3>
          </div>
          <div className="row">
            {workData.map((work, index) => (
              <div className="col-lg-6 col-md-6" key={index}>
                <div className="mokeup-designs">
                  <i>
                    <img src={work.image} alt="" />
                  </i>
                  <div className="mokeup-design-info d-block">
                    <a href={work.url}>{work.title}</a>
                    <p className="info-text">{work.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </>
  );
}

export default OurWork;
