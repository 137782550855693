import React from "react";
import "./WorkCards.css";
import { Container } from "react-bootstrap";

function WorkCards() {
  return (
    <>
      <section className="work-cards">
        <Container>
          <div className="row gy-4">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="work-card">
                <h4 className="work-card-title">UI/UX</h4>
                <p className="info-text">
                  UI (User Interface) design and UX (User Experience) design are
                  two closely related fields that play a crucial role in
                  creating effective digital products. A well-designed interface
                  can make a product more attractive and engaging to users.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="work-card green">
                <h4 className="work-card-title">Web Development</h4>
                <p className="info-text">
                  Web development is the creation of online sites and
                  applications that people can access using the internet. This
                  involves many different tasks, such as designing how a website
                  looks, writing computer code to make it work, organizing and
                  managing information stored in databases, and creating scripts
                  that run on the server.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="work-card orange">
                <h4 className="work-card-title">IOS / Android</h4>
                <p className="info-text">
                  iOS and Android are widely used mobile operating systems that
                  power a large number of smartphones, tablets, and other
                  portable devices. Both platforms have their own distinct
                  features and abilities, making them highly popular choices
                  among users worldwide.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="work-card yellow">
                <h4 className="work-card-title">SEO / Marketing</h4>
                <p className="info-text">
                  Both SEO (Search Engine Optimization) and marketing play
                  crucial roles in the success of businesses in the online
                  realm. SEO assists businesses in drawing increased natural
                  traffic to their websites, whereas marketing aids in reaching
                  a broader audience and effectively promoting their products or
                  services.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default WorkCards;
