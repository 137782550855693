import React, { useRef, useState } from "react";
import "./ContactUs.css";
import { Icon } from "@iconify/react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState("");

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  // ======================== error=============================
  const [formValid, setFormValid] = useState(false);

  const [checkboxError, setCheckboxError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [messageError, setMessageError] = useState(false);
  const [budgetError, setBudgetError] = useState(false);

  // ======================== error=============================

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedCheckboxes((prevCheckboxes) => [...prevCheckboxes, value]);
      setCheckboxError(false);
    } else {
      setSelectedCheckboxes((prevCheckboxes) =>
        prevCheckboxes.filter((checkbox) => checkbox !== value)
      );
    }
  };

  const handleClick = (alertIcon, title) => {
    Swal.fire({
      position: "center-center",
      icon: alertIcon,
      title: title,
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const form = useRef();

  const fileInputRef = useRef();
  // const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);

    files.forEach((file) => {
      console.log(file.name);
    });
  };

  const resetForm = () => {
    form.current.reset();
    setSelectedCheckboxes([]);
    setSelectedFiles([]);
    setName("");
    setEmail("");
    setMessage("");
    setBudget("");
  };

  const sendEmail = (e) => {
    e.preventDefault();
    // if (!name || !email || !message || !budget) {
    //   setNameError(!name);
    //   setEmailError(!email);
    //   setMessageError(!message);
    //   setBudgetError(!budget);
    //   setFormValid(true);
    //   return;
    // }
    // setFormValid(true);

    // Reset all error states
    // setFormValid(true);
    setCheckboxError(false);
    setNameError(false);
    setEmailError(false);
    setMessageError(false);
    setBudgetError(false);

    let hasErrors = false;

    if (selectedCheckboxes.length === 0) {
      setCheckboxError(true);
       hasErrors = true;
    }
    if (name.trim() === "") {
      setNameError(true);
       hasErrors = true;
    }
    if (email.trim() === "" || !emailPattern.test(email)) {
      setEmailError(true);
       hasErrors = true;
    }
    if (message.trim() === "") {
      setMessageError(true);
       hasErrors = true;
    }
    if (!budget) {
      setBudgetError(true);
       hasErrors = true;
    }
    if (hasErrors) {
      return;
    }
  

    // if (selectedCheckboxes.length === 0) {
    //   setCheckboxError(true);
    //   setFormValid(false);
    // }
    // if (name.trim() === "") {
    //   setNameError(true);
    //   setFormValid(false);
    // }
    // if (email.trim() === "" || !emailPattern.test(email)) {
    //   setEmailError(true);
    //   setFormValid(false);
    // }
    // if (message.trim() === "") {
    //   setMessageError(true);
    //   setFormValid(false);
    // }
    // if (!budget) {
    //   setBudgetError(true);
    //   setFormValid(false);
    // }

    // Return if any field has an error
    // if (!formValid) {
    //   return;
    // }


    const data = {
      user_name: form.current.user_name.value,
      interest: selectedCheckboxes.join(", "),
      user_email: form.current.user_email.value,
      message: form.current.message.value,
      options: budget,
      // files: selectedFiles.map((file) => file.name).join(", "),
    };

    emailjs
      .send("service_71a29qr", "template_wb162og", data, "aPUF7QBQ1QaTyDNjV")
      .then(
        (result) => {
          console.log(result);
          handleClick("success", "Your Email Was Sent Successfully")
          resetForm()
        },
        (error) => {
          console.log(error.text);
          handleClick("error", "An error occurred while sending the email.")
        }
      );
    e.target.reset();
    resetForm();
  };

  // =============================send email complete=====================

  return (
    <>
      <section id="Contact-Us" className="blue">
        <div className="container">
          <div className="contact-info">
            <h2>Hello There!</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="group-input">
                <label
                  for="exampleFormControlInput1"
                  className="form-label from-label-style"
                >
                  I'm interested in...
                </label>
                <ul className="im-interested">
                  <li className="ps-0">
                    <input
                      type="checkbox"
                      id="check_1"
                      name="check_1"
                      value="Site from Scratch"
                      checked={selectedCheckboxes.includes("Site from Scratch")}
                      onChange={handleCheckboxChange}
                    />
                    <label for="check_1">Site from Scratch</label>
                  </li>
                  <li className="web-development4">
                    <input
                      type="checkbox"
                      id="check_2"
                      name="check_2"
                      value="App from Scratch"
                      checked={selectedCheckboxes.includes("App from Scratch")}
                      onChange={handleCheckboxChange}
                    />
                    <label for="check_2">App from Scratch</label>
                  </li>
                  <li className="web-development3">
                    <input
                      type="checkbox"
                      id="check_3"
                      name="check_3"
                      value="UI/UX design"
                      checked={selectedCheckboxes.includes("UI/UX design")}
                      onChange={handleCheckboxChange}
                    />
                    <label for="check_3">UI/UX design</label>
                  </li>
                  <li className="web-development2">
                    <input
                      type="checkbox"
                      id="check_4"
                      name="check_4"
                      value="Branding"
                      checked={selectedCheckboxes.includes("Branding")}
                      onChange={handleCheckboxChange}
                    />
                    <label for="check_4">Branding</label>
                  </li>
                  <li className="web-development1">
                    <input
                      type="checkbox"
                      id="check_5"
                      name="check_5"
                      value="Mobile development"
                      checked={selectedCheckboxes.includes(
                        "Mobile development"
                      )}
                      onChange={handleCheckboxChange}
                    />
                    <label for="check_5">Mobile development</label>
                  </li>
                  <li className="web-development">
                    <input
                      type="checkbox"
                      id="check_6"
                      name="check_6"
                      value="Web development"
                      checked={selectedCheckboxes.includes("Web development")}
                      onChange={handleCheckboxChange}
                    />
                    <label for="check_6">Web development</label>
                  </li>
                  <li className="web-development">
                    <input
                      type="checkbox"
                      id="check_7"
                      name="check_7"
                      value="Maintenance"
                      checked={selectedCheckboxes.includes("Maintenance")}
                      onChange={handleCheckboxChange}
                    />
                    <label for="check_7">Maintenance</label>
                  </li>
                </ul>
                {checkboxError && (
                  <p className="validation-error text-danger">
                    Please select at least one option.
                  </p>
                )}
              </div>
              <div className="group-input">
                <label
                  for="exampleFormControlInput1"
                  className="form-label from-label-style"
                >
                  Name
                </label>
                <input
                  type="name"
                  name="user_name"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter you name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(false);
                  }}
                />
                {nameError && (
                  <p className="validation-error text-danger">
                    Please enter your name.
                  </p>
                )}
              </div>
              <div className="group-input">
                <label
                  for="exampleFormControlInput1"
                  className="form-label from-label-style"
                >
                  Email address
                </label>
                <input
                  type="email"
                  name="user_email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="name@gmail.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                />
                {emailError && (
                  <p className="validation-error text-danger">
                    Please enter a valid email address.
                  </p>
                )}
              </div>
              <div className="group-input">
                <label
                  for="exampleFormControlTextarea1"
                  className="form-label from-label-style"
                >
                  Tell us about your project
                </label>
                <textarea
                  className="form-control"
                  name="message"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Project detail"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setMessageError(false);
                  }}
                ></textarea>
                {messageError && (
                  <p className="validation-error text-danger">
                    Please enter a message for your project.
                  </p>
                )}
              </div>
              <div className="group-input">
                <label
                  for="exampleFormControlTextarea1"
                  className="form-label from-label-style"
                >
                  Project budget(USD)
                </label>
                <div className="usd-values">
                  <label className="usd-value-label">
                    <input
                      type="radio"
                      name="options"
                      value="10-20k"
                      checked={budget === "10-20k"}
                      onChange={(e) => {
                        setBudget(e.target.value);
                        setBudgetError(false);
                      }}
                    />
                    <span>10-20k</span>
                  </label>
                  <label className="usd-value-label">
                    <input
                      type="radio"
                      name="options"
                      value="30-40k"
                      checked={budget === "30-40k"}
                      onChange={(e) => {
                        setBudget(e.target.value);
                      }}
                    />
                    <span>30-40k</span>
                  </label>
                  <label className="usd-value-label">
                    <input
                      type="radio"
                      name="options"
                      value="40-50k"
                      checked={budget === "40-50k"}
                      onChange={(e) => {
                        setBudget(e.target.value);
                      }}
                    />
                    <span>40-50k</span>
                  </label>
                  <label className="usd-value-label">
                    <input
                      type="radio"
                      name="options"
                      value="50-100k"
                      checked={budget === "50-100k"}
                      onChange={(e) => {
                        setBudget(e.target.value);
                      }}
                    />
                    <span>50-100k</span>
                  </label>
                  <label className="usd-value-label">
                    <input
                      type="radio"
                      name="options"
                      value=">100k"
                      checked={budget === ">100k"}
                      onChange={(e) => {
                        setBudget(e.target.value);
                      }}
                    />
                    <span> > 100k</span>
                  </label>
                </div>
                {budgetError && (
                  <p className="validation-error text-danger">
                    Please select a project budget.
                  </p>
                )}
              </div>

              {/* <div className="upload-card">
                <div className="file-upload">
                  <div className="file-select">
                    <button
                      className="btn add-attachment-btn"
                      type="button"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <i>
                        <Icon icon="entypo:attachment" />
                      </i>
                      <span>Add attachment</span>
                    </button>
                    <div className="file-select-name">
                      {selectedFiles.length > 0 ? (
                        <ul>
                          {selectedFiles.map((file, index) => (
                            <li key={index}>{file.name}</li>
                          ))}
                        </ul>
                      ) : (
                        // ? selectedFiles.map((file) => file.name).join(", ")
                        "No File Chosen"
                      )}
                    </div>
                    <div className="imagePreview"></div>
                    <input
                      type="file"
                      name="files[]"
                      className="profileimg"
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                      multiple
                    />
                  </div>
                </div>
                <button
                  className="file-add"
                  onClick={() => fileInputRef.current.click()}
                >
                  Add More File
                </button>
              </div> */}

              
              {/* {!formValid && (
                <p className="validation-error text-danger">
                  Please fill in all the required fields.
                </p>
              )} */}
              <button
                type="submit"
                className="send-request"
                // onClick={handleClick}
                // disabled={!formValid}
              >
                Send request
              </button>
              <p className="policy-text">
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="#">Privacy Policy</a> and{" "}
                <a href="#">Terms of Service</a> apply.
              </p>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
