import React from "react";
import "./Brands.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import brandlogo1 from "../../assets/images/brandlogo1.png";
import brandlogo2 from "../../assets/images/brandlogo2.png";
import brandlogo3 from "../../assets/images/brandlogo3.png";
import brandlogo4 from "../../assets/images/brandlogo4.png";
import brandlogo5 from "../../assets/images/brandlogo5.png";
import brandlogo6 from "../../assets/images/brandlogo6.png";
import brandlogo7 from "../../assets/images/brandlogo7.png";
import brandlogo8 from "../../assets/images/brandlogo8.png";
import { Container } from "react-bootstrap";

function Brands() {
  const settings = {
    className: "center",
    autoplay: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    speed: 1000,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

    // afterChange: function (index) {
    //   console.log(
    //     `Slider Changed to: ${index + 1}, background: #222; color: #00000`
    //   );
    // },
  };
  return (
    <>
    <section class="brands">
      <Container>
        <Slider {...settings}>
          <div className="item">
            <a href="https://www.orbofi.com/" class="brand-imgs">
              <img src={brandlogo1} alt="" />
            </a>
          </div>
          <div className="item">
            <a href="https://noteefy.app/" class="brand-imgs">
              <img src={brandlogo2} alt="" />
            </a>
          </div>
          <div className="item">
            <a href="https://elocker.com/" class="brand-imgs">
              <img src={brandlogo3} alt="" />
            </a>
          </div>
          <div className="item">
            <a href="https://shurooq.gov.ae/" class="brand-imgs">
              <img src={brandlogo4} alt="" />
            </a>
          </div>
          <div className="item">
            <a href="#" class="brand-imgs">
              <img src={brandlogo5} alt="" />
            </a>
          </div>
          <div className="item">
            <a href="#" class="brand-imgs">
              <img src={brandlogo6} alt="" />
            </a>
          </div>
          <div className="item">
            <a href="#" class="brand-imgs">
              <img src={brandlogo7} alt="" />
            </a>
          </div>
          <div className="item">
            <a href="#" class="brand-imgs">
              <img src={brandlogo8} alt="" />
            </a>
          </div>
        </Slider>
      </Container>
      {/* Brands Close  */}
    </section>
    </>
  );
}

export default Brands;
