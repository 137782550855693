import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/images/brand_logo.svg";
import "./Header.css";
import { Link } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";

const Header = () => {
  const [loading, setLoading] = useState(false);

  // Function to simulate loading data
  const fetchData = () => {
    setLoading(true);
    // Simulating an API call
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const [navbar, setnavbar] = useState(false);

  const changeHeight = () => {
    if (window.scrollY >= 50) {
      setnavbar(true);
    } else {
      setnavbar(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  window.addEventListener("scroll", changeHeight);

  return (
    <>
      <header>
        <Navbar className={navbar ? "navbar active" : "navbar"}>
          <Container>
            <Navbar.Brand href="#home">
              <div className="navbar-brand">
                <img src={logo} />
              </div>
            </Navbar.Brand>
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="ms-auto">
                <Link to="/contact-us" id="navbarNav" onClick={scrollToTop}>
                  Contact Us
                </Link>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
